import { fetchConToken, fetchSinToken } from "../helpers/fetch";
import Swal from "sweetalert2";
import { types } from '../types/types'


export const startLogin = ( rut, password ) => {
    return async (dispatch) => {
        dispatch(authStartLogin())
        const resp = await fetchSinToken( 'auth/loginUsuario', { rut, password }, 'POST' );
        const body = await resp.json();
        if ( body.code === 'MNS.RMS.0200' ) {
            localStorage.setItem('token', body.result.payload.token );
            localStorage.setItem('token-init-date', new Date().getTime());
            Swal.fire({
                icon: 'success',
                title: `${body.message}`,
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(login({
                uid: body.result.payload.uid,
                nombres: body.result.payload.nombres,
            }));
        } else {
            Swal.fire('Error', body.message ,'error');
        }
    };
};

const authStartLogin = () => ({ type: types.authStartLogin });


export const startChecking = ()=>{
    return async(dispatch) => {
        const resp = await fetchConToken( 'auth/revalidarTokenUsuario' );
        const body = await resp.json();
        console.log(body)
        if ( body.code === 'MNS.RMS.0200' ) {
            localStorage.setItem('token', body.result.payload.token );
            localStorage.setItem('token-init-date', new Date().getTime());
            dispatch(login({
                uid: body.result.payload.uid,
                nombres: body.result.payload.nombres,
                cargo: body.result.payload.cargo
            }));
            
        } else {
            dispatch(checkingFinish());
        }
    }
};
const checkingFinish = () => ({ type: types.authCheckingFinish });



const login =( user ) => ({
    type: types.authLogin,
    payload: user
});

export const startLogout = ()=> {
    return (dispatch) => {
        localStorage.clear();
        dispatch(logout())
    }
}

const logout = () => ({
    type: types.authLogout
});

export const types = {
    //Router
    // administrador establecimiento
    establecimientosTabla: '[admin-establecimiento] Tabla principal',
    establecimientoFormNuevo: '[admin-establecimiento] Form Nuevo',
    establecimientoFormActualizar: '[admin-establecimiento] Form Actualizar',
    establecimientoContenido: '[admin-establecimiento] Contenido',
    
    establecimientoNoticiasPrincipal: '[admin-establecimiento] Noticias principal',
    //noticias establecimiento
    establecimientoTablaNoticias: '[admin-establecimiento-noticia] Tabla Noticias',
    establecimientoFormNoticiaNueva: '[admin-establecimiento-noticia] Form Nuevo',
    establecimientoFormNoticiaActualizar: '[admin-establecimiento-noticia] Form Actualizar',
    //categoria noticia establecimiento
    establecimientoTablaCategoriaNoticias: '[admin-establecimiento-categoria-noticia] Tabla Categoria Noticias',
    establecimientoFormCategoriaNueva: '[admin-establecimiento-categoria-noticia] Form Nueva',
    establecimientoFormCategoriActualizar: '[admin-establecimiento-categoria-noticia] Form Actualizar',

    // informacion establecimiento
    establecimientoFormInformacionNueva:'[admin-establecimiento-informacion] Form Nuevo',
    establecimientoFormInformacionActualizar:'[admin-establecimiento-informacion] Form Actualizar',

    // administrador usuarios
    usuarioTablaEstablecimientos: '[admin-usuario] Tabla establecimientos',
    usuarioTablaUsuarios: '[admin-usuario] Tabla Usuarios',
    usuarioFicha: '[admin-usuario] Ficha Usuario',
    usuarioFormNuevo: '[admin-usuario] Form Nuevo Usuario',
    usuarioFormActualizar: '[admin-usuario] Form actualizar Usuario',
    usuarioTablaCredenciales: '[admin-usuario] Tabla credenciales',
    usuarioFormCredencialNuevo: '[admin-usuario] Form Nueva Credencial',
    usuarioFormCredencialActualizar: '[admin-usuario] Form Actualizar Credencial',
    usuarioFormInformacionNueva: '[admin-usuario] Form Informacion Nueva',
    usuarioFormInformacionActualizar: '[admin-usuario] Form Informacion Actualizar',

    // administrador estudiantes
    estudianteTablaEstablecimientos: '[admin-estudiante] tabla establecimientos',
    estudianteTablaEstudiantes: '[admin-estudiante] tabla estudiantes',
    estudianteFicha: '[admin-estudiante] ficha',
    estudianteFormNuevo: '[admin-estudiante] form nuevo',
    estudianteFormActualizar: '[admin-estudiante] form actualizar',
    estudianteTablaCredenciales: '[admin-estudiante] tabla credenciales',
    estudianteFormCredencialNueva: '[admin-estudiante] form credencial nueva',
    estudianteFormCredencialActualizar: '[admin-estudiante] form credencial actualizar',
    estudianteFormInformacionNueva: '[admin-estudiante] form informacion nueva',
    estudianteFormInformacionActualizar: '[admin-estudiante] form informacion actualizar',


    libroDigitalTablaEstablecimientos: '[libroDigital] Tabla establecimientos',
    libroDigitalTablaAniosEscolares: '[libroDigital] Tabla anios escoalres',
    libroDigitalFormAnioEscolarNuevo: '[libroDigital] form nuevo anioEscolar',
    libroDigitalFormAnioEscolarActualizar: '[libroDigital]  form actualizar anioescolar',
    libroDigitalTablaPeriodosEscolares: '[libroDigital] Tabla periodos escolares',
    libroDigitalFormPeriodoEscolarNuevo: '[libroDigital] Form Nuevo periodo escolar',
    libroDigitalFormPeriodoEscolarActualizar: '[libroDigital] Form actualizar periodo escolar',
    libroDigitalTablaPeriodosCurso: '[libroDigital] Tabla periodos curso',
    libroDigitalTablaCursos: '[libroDigital] Tabla cursos',
    libroDigitalFormCursoNuevo: '[libroDigital] Form nuevo curso',
    libroDigitalFormCursoActualizar: '[libroDigital] Form actualizar curso',
    libroDigitalTablaAsignaturas: '[libroDigital] Tabla asignaturas',
    libroDigitalFormAsignaturaNueva: '[libroDigital] Form nueva asignatura',
    libroDigitalFormAsignaturaActualizar: '[libroDigital] Form actualizar Asignatura',
    libroDigitalTablaTalleres: '[libroDigital]  Tabla talleres',
    libroDigitalFormTallerNuevo: '[libroDigital] Form nuevo taller',
    libroDigitalFormTallerActualizar: '[libroDigital] Form actualizar taller',
    libroDigitalTablaEstudiantes: '[libroDigital] Tabla estudiantes',
    libroDigitalTablaMatriculasEstudiantes: '[libroDigital] Tabla matriculas estudiantes',
    libroDigitalFormMatriculaNueva: '[libroDigital] Form Nueva matricula',
    libroDigitalFormMatriculaActualizar: '[libroDigital] Form Actualizar matricula',


    // CRUD 
    //establecimiento 
    crearEstablecimientoOk:'[establecimiento] crear ok',
    crearEstablecimientoError:'[establecimiento] crear error',
    obtenerEstablecimientosOk:'[establecimientos] obtener ok',
    obtenerEstablecimientosError:'[establecimientos] obtener error',
    actualizarEstablecimientoOk:'[establecimiento] actualizar ok',
    actualizarEstablecimientoError:'[establecimiento] actualizar error',
    activarEstablecimiento:'[establecimiento] activo',

    // categoria noticia
    crearCategoriaNoticiaOk:'[categoria-noticia] crear ok',
    crearCategoriaNoticiaError:'[categoria-noticia] crear error',
    obtenerCategoriasNoticiaOk:'[categoria-noticia] obtener ok',
    obtenerCategoriasNoticiaError:'[categoria-noticia] obtener error',
    actualizarCategoriaNoticiaOk:'[categoria-noticia] actualizar ok',
    actualizarCategoriaNoticiaError:'[categoria-noticia] actualizar error',
    activarCategoriaNoticia:'[categoria-noticia] activar ok',
    
    // noticia
    crearNoticiaOk:'[noticia] crear ok',
    crearNoticiaError:'[noticia] crear error',
    obtenerNoticiasOk:'[noticia] obtener ok',
    obtenerNoticiasError:'[noticia] obtener error',
    actualizarNoticiaOk:'[noticia] actualizar ok',
    actualizarNoticiaError:'[noticia] actualizar error',
    activarNoticia:'[noticia] activar ok',
    
    // informacion establecimiento
    crearEstablecimientoInformacionOk:'[informacion-establecimiento] crear ok',
    crearEstablecimientoInformacionError:'[informacion-establecimiento] crear error',
    obtenerEstablecimientoInformacionOk:'[informacion-establecimiento] obtener ok',
    obtenerEstablecimientoInformacionError:'[informacion-establecimiento] obtener error',
    actualizarEstablecimientoInformacionOk:'[informacion-establecimiento] actualizar ok',
    actualizarEstablecimientoInformacionError:'[informacion-establecimiento] actualizar error',
    activarEstablecimientoInformacion:'[informacion-establecimiento] activar ok',
    limpiarEstablecimientoInformacion:'[informacion-establecimiento] limpiar ok',


    // usuario
    crearUsuarioOk:'[usuario] crear ok',
    crearUsuarioError:'[usuario] crear error',
    obtenerUsuariosOk:'[usuario] obtener ok',
    obtenerUsuariosError:'[usuario] obtener error',
    obtenerDocentesOk:'[usuario] obtener docentes ok',
    obtenerDocentesError:'[usuario] obtener docentes error',
    actualizarUsuarioOk:'[usuario] actualizar ok',
    actualizarUsuarioError:'[usuario] actualizar error',
    activarUsuario:'[usuario] activar ok',
    
    //credencial usuario
    crearCredencialUsuarioOk:'[credencial-usuario] crear ok',
    crearCredencialUsuarioError:'[credencial-usuario] crear error',
    obtenerCredencialesUsuarioOk:'[credencial-usuario] obtener ok',
    obtenerCredencialesUsuarioError:'[credencial-usuario] obtener error',
    actualizarCredencialUsuarioOk:'[credencial-usuario] actualizar ok',
    actualizarCredencialUsuarioError:'[credencial-usuario] actualizar error',
    activarCredencialUsuario:'[credencial-usuario] activar ok',
    limpiarCredencialesUsuario:'[credencial-usuario] limpiar ok',
    
    // informacion usuario
    crearUsuarioInformacionOk:'[informacion-usuario] crear ok',
    crearUsuarioInformacionError:'[informacion-usuario] crear error',
    obtenerUsuarioInformacionOk:'[informacion-usuario] obtener ok',
    obtenerUsuarioInformacionError:'[informacion-usuario] obtener error',
    actualizarUsuarioInformacionOk:'[informacion-usuario] actualizar ok',
    actualizarUsuarioInformacionError:'[informacion-usuario] actualizar error',
    activarUsuarioInformacion:'[informacion-usuario] activar ok',
    limpiarUsuarioInformacion:'[informacion-usuario] limpiar ok',

    // estudiante
    crearEstudianteOk:'[estudiante] crear ok',
    crearEstudianteError:'[estudiante] crear error',
    obtenerEstudiantesOk:'[estudiante] obtener ok',
    obtenerEstudiantesError:'[estudiante] obtener error',
    actualizarEstudianteOk:'[estudiante] actualizar ok',
    actualizarEstudianteError:'[estudiante] actualizar error',
    activarEstudiante:'[estudiante] activar ok',

    // estudiante credencial
    crearCredencialEstudianteOk:'[credencial-estudiante] crear ok',
    crearCredencialEstudianteError:'[credencial-estudiante] crear error',
    obtenerCredencialesEstudianteOk:'[credencial-estudiante] obtener ok',
    obtenerCredencialesEstudianteError:'[credencial-estudiante] obtener error',
    actualizarCredencialEstudianteOk:'[credencial-estudiante] actualizar ok',
    actualizarCredencialEstudianteError:'[credencial-estudiante] actualizar error',
    activarCredencialEstudiante:'[credencial-estudiante] activar ok',
    limpiarCredencialesEstudiante:'[credencial-estudiante] limpiar ok',
    

    // libro digital crud
    // anio escolar
    crearAnioEscolarOk:'[AnioEscolar] crear ok',
    crearAnioEscolarError:'[AnioEscolar] crear error',
    obtenerAniosEscolaresOk:'[AnioEscolar] obtener ok',
    obtenerAniosEscolaresError:'[AnioEscolar] obtener error',
    actualizarAnioEscolarOk:'[AnioEscolar] actualizar ok',
    actualizarAnioEscolarError:'[AnioEscolar] actualizar error',
    activarAnioEscolar:'[AnioEscolar] activar ok',
    limpiarAniosEscolares:'[AnioEscolar] limpiar ok',
    
    // periodo escolar
    crearPeriodoEscolarOk:'[PeriodoEscolar] crear ok',
    crearPeriodoEscolarError:'[PeriodoEscolar] crear error',
    obtenerPeriodosEscolaresOk:'[PeriodoEscolar] obtener ok',
    obtenerPeriodosEscolaresError:'[PeriodoEscolar] obtener error',
    actualizarPeriodoEscolarOk:'[PeriodoEscolar] actualizar ok',
    actualizarPeriodoEscolarError:'[PeriodoEscolar] actualizar error',
    activarPeriodoEscolar:'[PeriodoEscolar] activar ok',
    limpiarPeriodosEscolares:'[PeriodoEscolar] limpiar ok',
    
    // Cursos
    crearCursoOk:'[Curso] crear ok',
    crearCursoError:'[Curso] crear error',
    obtenerCursosOk:'[Curso] obtener ok',
    obtenerCursosError:'[Curso] obtener error',
    actualizarCursoOk:'[Curso] actualizar ok',
    actualizarCursoError:'[Curso] actualizar error',
    activarCurso:'[Curso] activar ok',
    limpiarCursos:'[Curso] limpiar ok',
    
    // asignaturas
    crearAsignaturaOk:'[Asignatura] crear ok',
    crearAsignaturaError:'[Asignatura] crear error',
    obtenerAsignaturasOk:'[Asignatura] obtener ok',
    obtenerAsignaturasError:'[Asignatura] obtener error',
    actualizarAsignaturaOk:'[Asignatura] actualizar ok',
    actualizarAsignaturaError:'[Asignatura] actualizar error',
    activarAsignatura:'[Asignatura] activar ok',
    limpiarAsignaturas:'[Asignatura] limpiar ok',
    
    // talleres
    crearTallerOk:'[Taller] crear ok',
    crearTallerError:'[Taller] crear error',
    obtenerTalleresOk:'[Taller] obtener ok',
    obtenerTalleresError:'[Taller] obtener error',
    actualizarTallerOk:'[Taller] actualizar ok',
    actualizarTallerError:'[Taller] actualizar error',
    activarTaller:'[Taller] activar ok',
    limpiarTalleres:'[Taller] limpiar ok',
    
    // matriculas
    crearMatriculaOk:'[Matricula] crear ok',
    crearMatriculaError:'[Matricula] crear error',
    obtenerMatriculasOk:'[Matricula] obtener ok',
    obtenerMatriculasError:'[Matricula] obtener error',
    actualizarMatriculaOk:'[Matricula] actualizar ok',
    actualizarMatriculaError:'[Matricula] actualizar error',
    activarMatricula:'[Matricula] activar ok',
    limpiarMatriculas:'[Matricula] limpiar ok',
    

    // ********************** ARCHIVOS **********************
    startUploadingOk: '[File] upload ok',
    startUploadingError: '[File] upload error',
    clearUploadFile: '[File] clear file',

    // ********************** Login **********************
    authStartLogin: '[auth] Start login',
    authCheckingFinish: '[auth] checking finish',
    authLogin: '[auth] authLogin',
    authLogout: '[auth] Logout',


};
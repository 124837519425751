import { types } from "../types/types";

const initialState = {
  estudiantes:[],
  estudianteActivo:'',
  credencialesEstudiante:[],
  credencialEstudianteActiva:'',
  matriculas:[],
  matriculasActiva:{},
};

export const estudianteReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearEstudianteOk:
      return {
        ...state,
        estudiantes:[ ...state.estudiantes, action.payload ]
      }
    case types.obtenerEstudiantesOk:
      return {
        ...state,
        estudiantes: action.payload
      }
    case types.actualizarEstudianteOk:
      return {
        ...state,
        estudiantes: state.estudiantes.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarEstudiante:
      return {
        ...state,
        estudianteActivo: action.payload
      }
      // credencial estudiante
    case types.crearCredencialEstudianteOk:
      return {
        ...state,
        credencialesEstudiante:[ ...state.credencialesEstudiante, action.payload ]
      }
    case types.obtenerCredencialesEstudianteOk:
      return {
        ...state,
        credencialesEstudiante: action.payload
      }
    case types.actualizarCredencialEstudianteOk:
      return {
        ...state,
        credencialesEstudiante: state.credencialesEstudiante.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarCredencialEstudiante:
      return {
        ...state,
        credencialEstudianteActiva: action.payload
      }
      
    case types.limpiarCredencialesEstudiante:
      return {
        ...state,
        credencialesEstudiante: []
      }
      // matriculas estudiante
    case types.crearMatriculaOk:
      return {
        ...state,
        matriculas:[ ...state.matriculas, action.payload ]
      }
    case types.obtenerMatriculasOk:
      return {
        ...state,
        matriculas: action.payload
      }
    case types.actualizarMatriculaOk:
      return {
        ...state,
        matriculas: state.matriculas.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
    case types.activarMatricula:
      return {
        ...state,
        matriculaActiva: action.payload
      }
      
    case types.limpiarMatriculas:
      return {
        ...state,
        matriculas: []
      }
      // informacion estudiante
    
      
    default:
      return state;
  }
};
import { types } from "../types/types";

const initialState = {
  aniosEscolares:[],
  anioEscolarActivo:'',
  periodosEscolares:[],
  periodoEscolarActivo:'',
  cursos:[],
  cursoActivo:'',
  asignaturas:[],
  asignaturaActiva:'',
  talleres:[],
  tallerActivo:'',
};

export const libroDigitalReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.crearAnioEscolarOk:
      return {
        ...state,
        aniosEscolares:[ ...state.aniosEscolares, action.payload ]
      }
    case types.obtenerAniosEscolaresOk:
      return {
        ...state,
        aniosEscolares: action.payload
      }
    case types.actualizarAnioEscolarOk:
      return {
        ...state,
        aniosEscolares: state.aniosEscolares.map(
            e => (e._id === action.payload._id) ? action.payload : e
        )
      }
      case types.activarAnioEscolar:
        return {
          ...state,
          anioEscolarActivo: action.payload
        }
      case types.limpiarAniosEscolares:
        return {
          ...state,
          aniosEscolares: []
        }
      // periodosEscolares
      case types.crearPeriodoEscolarOk:
        return {
          ...state,
          periodosEscolares:[ ...state.periodosEscolares, action.payload ]
        }
      case types.obtenerPeriodosEscolaresOk:
        return {
          ...state,
          periodosEscolares: action.payload
        }
      case types.actualizarPeriodoEscolarOk:
        return {
          ...state,
          periodosEscolares: state.periodosEscolares.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarPeriodoEscolar:
        return {
          ...state,
          periodoEscolarActivo: action.payload
        }
      case types.limpiarPeriodosEscolares:
        return {
          ...state,
          periodosEscolares: []
        }
      //  cursos
      case types.crearCursoOk:
        return {
          ...state,
          cursos:[ ...state.cursos, action.payload ]
        }
      case types.obtenerCursosOk:
        return {
          ...state,
          cursos: action.payload
        }
      case types.actualizarCursoOk:
        return {
          ...state,
          cursos: state.cursos.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarCurso:
        return {
          ...state,
          cursoActivo: action.payload
        }
      case types.limpiarCursos:
        return {
          ...state,
          cursos: []
        }
      //  asignaturas
      case types.crearAsignaturaOk:
        return {
          ...state,
          asignaturas:[ ...state.asignaturas, action.payload ]
        }
      case types.obtenerAsignaturasOk:
        return {
          ...state,
          asignaturas: action.payload
        }
      case types.actualizarAsignaturaOk:
        return {
          ...state,
          asignaturas: state.asignaturas.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarAsignatura:
        return {
          ...state,
          asignaturaActiva: action.payload
        }
      case types.limpiarAsignaturas:
        return {
          ...state,
          asignaturas: []
        }
      
      //  talleres
      case types.crearTallerOk:
        return {
          ...state,
          talleres:[ ...state.talleres, action.payload ]
        }
      case types.obtenerTalleresOk:
        return {
          ...state,
          talleres: action.payload
        }
      case types.actualizarTallerOk:
        return {
          ...state,
          talleres: state.talleres.map(
              e => (e._id === action.payload._id) ? action.payload : e
          )
        }
      case types.activarTaller:
        return {
          ...state,
          tallerActivo: action.payload
        }
      case types.limpiarTalleres:
        return {
          ...state,
          talleres: []
        }
      
    default:
      return state;
  }
};
import { types } from "../types/types";

const initialState = {
  //establecimiento router
  establecimientoFormNuevo: false,
  establecimientoFormActualizar: false,
  establecimientosTabla: true,
  establecimientoContenido: false,
  establecimientoNoticiasPrincipal: false,
  establecimientoFormInformacionActualizar: false,

  establecimientoTablaNoticias: false,
  establecimientoFormNoticiaNueva: false,
  establecimientoFormNoticiaActualizar: false,
  establecimientoTablaCategoriaNoticias: false,
  establecimientoFormCategoriaNueva: false,
  establecimientoFormCategoriActualizar: false,

  // usuario router
  usuarioTablaEstablecimientos: true,
  usuarioTablaUsuarios: false,
  usuarioFicha: false,
  usuarioFormNuevo: false,
  usuarioFormActualizar: false,
  usuarioTablaCredenciales: false,
  usuarioFormCredencialNuevo: false,
  usuarioFormCredencialActualizar: false,
  usuarioFormInformacionNueva: false,
  usuarioFormInformacionActualizar: false,

  //estudiantes router
  estudianteTablaEstablecimientos: true,
  estudianteTablaEstudiantes: false,
  estudianteFicha: false,
  estudianteFormNuevo: false,
  estudianteFormActualizar: false,
  estudianteTablaCredenciales: false,
  estudianteFormCredencialNueva: false,
  estudianteFormCredencialActualizar: false,
  estudianteFormInformacionNueva: false,
  estudianteFormInformacionActualizar: false,

  // libro digital router
  libroDigitalTablaEstablecimientos: true,
  libroDigitalTablaAniosEscolares: false,
  libroDigitalFormAnioEscolarNuevo: false,
  libroDigitalFormAnioEscolarActualizar: false,
  libroDigitalTablaPeriodosEscolares: false,
  libroDigitalFormPeriodoEscolarNuevo: false,
  libroDigitalFormPeriodoEscolarActualizar: false,
  libroDigitalTablaPeriodosCurso: false,
  libroDigitalTablaCursos: false,
  libroDigitalFormCursoNuevo: false,
  libroDigitalFormCursoActualizar: false,
  libroDigitalTablaAsignaturas: false,
  libroDigitalFormAsignaturaNueva: false,
  libroDigitalFormAsignaturaActualizar: false,
  libroDigitalTablaTalleres: false,
  libroDigitalFormTallerNuevo: false,
  libroDigitalFormTallerActualizar: false,
  libroDigitalTablaEstudiantes: false,
  libroDigitalTablaMatriculasEstudiantes :false,
  libroDigitalFormMatriculaNueva: false,
  libroDigitalFormMatriculaActualizar: false,
 
};

export const routerReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.establecimientoFormNuevo:
      return {
        ...state,
        establecimientoFormNuevo: true,
        establecimientoFormActualizar: false,
        establecimientosTabla: false,
        establecimientoContenido: false,
        establecimientoNoticiasPrincipal: false,
      }
    case types.establecimientoFormActualizar:
      return {
        ...state,
        establecimientoFormNuevo: false,
        establecimientoFormActualizar: true,
        establecimientosTabla: false,
        establecimientoContenido: false,
        establecimientoNoticiasPrincipal: false,
      }
    case types.establecimientosTabla:
      return {
        ...state,
        establecimientoFormNuevo: false,
        establecimientoFormActualizar: false,
        establecimientosTabla: true,
        establecimientoContenido: false,
        establecimientoNoticiasPrincipal: false,
      }
    case types.establecimientoContenido:
      return {
        ...state,
        establecimientoFormNuevo: false,
        establecimientoFormActualizar: false,
        establecimientosTabla: false,
        establecimientoContenido: true,
        establecimientoNoticiasPrincipal: false,
        establecimientoFormInformacionActualizar: false,
      }
    case types.establecimientoNoticiasPrincipal:
      return {
        ...state,
        establecimientoFormNuevo: false,
        establecimientoFormActualizar: false,
        establecimientosTabla: false,
        establecimientoContenido: false,
        establecimientoNoticiasPrincipal: true,
      }
    // establecimiento noticias y categoria
    case types.establecimientoTablaNoticias:
      return {
        ...state,
        establecimientoTablaNoticias: true,
        establecimientoFormNoticiaNueva: false,
        establecimientoFormNoticiaActualizar: false,
        establecimientoTablaCategoriaNoticias: false,
        establecimientoFormCategoriaNueva: false,
        establecimientoFormCategoriActualizar: false,
      }
    case types.establecimientoFormNoticiaNueva:
      return {
        ...state,
        establecimientoTablaNoticias: false,
        establecimientoFormNoticiaNueva: true,
        establecimientoFormNoticiaActualizar: false,
        establecimientoTablaCategoriaNoticias: false,
        establecimientoFormCategoriaNueva: false,
        establecimientoFormCategoriActualizar: false,
      }
    case types.establecimientoFormNoticiaActualizar:
      return {
        ...state,
        establecimientoTablaNoticias: false,
        establecimientoFormNoticiaNueva: false,
        establecimientoFormNoticiaActualizar: true,
        establecimientoTablaCategoriaNoticias: false,
        establecimientoFormCategoriaNueva: false,
        establecimientoFormCategoriActualizar: false,
      }
    case types.establecimientoTablaCategoriaNoticias:
      return {
        ...state,
        establecimientoTablaNoticias: false,
        establecimientoFormNoticiaNueva: false,
        establecimientoFormNoticiaActualizar: false,
        establecimientoTablaCategoriaNoticias: true,
        establecimientoFormCategoriaNueva: false,
        establecimientoFormCategoriActualizar: false,
      }
    case types.establecimientoFormCategoriaNueva:
      return {
        ...state,
        establecimientoTablaNoticias: false,
        establecimientoFormNoticiaNueva: false,
        establecimientoFormNoticiaActualizar: false,
        establecimientoTablaCategoriaNoticias: false,
        establecimientoFormCategoriaNueva: true,
        establecimientoFormCategoriActualizar: false,
      }
    case types.establecimientoFormCategoriActualizar:
      return {
        ...state,
        establecimientoTablaNoticias: false,
        establecimientoFormNoticiaNueva: false,
        establecimientoFormNoticiaActualizar: false,
        establecimientoTablaCategoriaNoticias: false,
        establecimientoFormCategoriaNueva: false,
        establecimientoFormCategoriActualizar: true,
      }

    case types.establecimientoFormInformacionActualizar:
      return {
        ...state,
        establecimientoFormInformacionActualizar: true,
        establecimientoContenido: false,
      }
      // usuario router
      
    case types.usuarioTablaEstablecimientos:
      return {
        ...state,
        usuarioTablaEstablecimientos: true,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioTablaUsuarios:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: true,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFicha:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: true,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormNuevo:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: true,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormActualizar:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: true,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioTablaCredenciales:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: true,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormCredencialNuevo:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: true,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormCredencialActualizar:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: true,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormInformacionNueva:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: true,
        usuarioFormInformacionActualizar: false,
      }
    case types.usuarioFormInformacionActualizar:
      return {
        ...state,
        usuarioTablaEstablecimientos: false,
        usuarioTablaUsuarios: false,
        usuarioFicha: false,
        usuarioFormNuevo: false,
        usuarioFormActualizar: false,
        usuarioTablaCredenciales: false,
        usuarioFormCredencialNuevo: false,
        usuarioFormCredencialActualizar: false,
        usuarioFormInformacionNueva: false,
        usuarioFormInformacionActualizar: true,
      }
    
      // estudiannte administrador
    case types.estudianteTablaEstablecimientos:
      return {
        ...state,
        estudianteTablaEstablecimientos: true,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteTablaEstudiantes:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: true,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFicha:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: true,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormNuevo:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: true,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormActualizar:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: true,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteTablaCredenciales:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: true,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormCredencialNueva:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: true,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormCredencialActualizar:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: true,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormInformacionNueva:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: true,
        estudianteFormInformacionActualizar: false,
      }
    case types.estudianteFormInformacionActualizar:
      return {
        ...state,
        estudianteTablaEstablecimientos: false,
        estudianteTablaEstudiantes: false,
        estudianteFicha: false,
        estudianteFormNuevo: false,
        estudianteFormActualizar: false,
        estudianteTablaCredenciales: false,
        estudianteFormCredencialNueva: false,
        estudianteFormCredencialActualizar: false,
        estudianteFormInformacionNueva: false,
        estudianteFormInformacionActualizar: true,
      }
      // libro digital
    case types.libroDigitalTablaEstablecimientos:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: true,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaAniosEscolares:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: true,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormAnioEscolarNuevo:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: true,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormAnioEscolarActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: true,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaPeriodosEscolares:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: true,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormPeriodoEscolarNuevo:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: true,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormPeriodoEscolarActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: true,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaPeriodosCurso:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: true,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaCursos:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: true,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormCursoNuevo:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: true,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormCursoActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: true,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaAsignaturas:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: true,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormAsignaturaNueva:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: true,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormAsignaturaActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: true,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaTalleres:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: true,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormTallerNuevo:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: true,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormTallerActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: true,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaEstudiantes:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: true,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalTablaMatriculasEstudiantes:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :true,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormMatriculaNueva:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: true,
        libroDigitalFormMatriculaActualizar: false,
      }
    case types.libroDigitalFormMatriculaActualizar:
      return {
        ...state,
        libroDigitalTablaEstablecimientos: false,
        libroDigitalTablaAniosEscolares: false,
        libroDigitalFormAnioEscolarNuevo: false,
        libroDigitalFormAnioEscolarActualizar: false,
        libroDigitalTablaPeriodosEscolares: false,
        libroDigitalFormPeriodoEscolarNuevo: false,
        libroDigitalFormPeriodoEscolarActualizar: false,
        libroDigitalTablaPeriodosCurso: false,
        libroDigitalTablaCursos: false,
        libroDigitalFormCursoNuevo: false,
        libroDigitalFormCursoActualizar: false,
        libroDigitalTablaAsignaturas: false,
        libroDigitalFormAsignaturaNueva: false,
        libroDigitalFormAsignaturaActualizar: false,
        libroDigitalTablaTalleres: false,
        libroDigitalFormTallerNuevo: false,
        libroDigitalFormTallerActualizar: false,
        libroDigitalTablaEstudiantes: false,
        libroDigitalTablaMatriculasEstudiantes :false,
        libroDigitalFormMatriculaNueva: false,
        libroDigitalFormMatriculaActualizar: true,
      }
   


    
    default:
      return state;
  }
};
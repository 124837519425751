import React from 'react'
import { Helmet } from 'react-helmet'
import { Provider } from 'react-redux'
import { store } from '../state/createStore'
import { Navbar } from './navbar'
import fondo from '../images/salem.gif'
import '../style/style.scss'


const Layout = ({children}) => {
    return (
        <Provider store={store} >
            <Helmet>
                <meta charset="utf-8" />
                <link rel="icon" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="theme-color" content="#fffff" />
                <meta name="description" content="Inscripciones EPJA" />
                <title>...:::Adminitstrador Colegio su santidad Juan Pablo II:::...</title>
                <link 
                    href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" 
                    rel="stylesheet" 
                    integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" 
                    crossOrigin="anonymous"
                />
  
                <script src="https://kit.fontawesome.com/b4a2746c93.js" crossOrigin="anonymous"></script>

            </Helmet>

            <main style={{
                backgroundImage:`url(${fondo})`, 
                backgroundRepeat:'repeat', 
                backgroundSize:'180px', 
                backgroundPosition:'fixed',
                height:'100%',
                minHeight:'100vh'
            }}>
                <Navbar />
                {children}
            </main>
            <script 
                src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js" 
                integrity="sha384-w76AqPfDkMBDXo30jS1Sgez6pr3x5MlQ1ZAGC+nuZB+EYdgRZgiwxhTBTkF7CXvN" 
                crossOrigin="anonymous">
            </script>
  
        </Provider>
    )
}

export default Layout
